import React from "react";
import { Link } from "gatsby";
import { resizeAndConvertImage } from "../../utils/common";

export default ({ data }) => {
  return (
    <React.Fragment>
      <Link
        to={`/blog/${data.slug}`}
        className="shadow-box videos w-inline-block"
      >
        <div className="video-container">
          {data.featureImage && <img
            src={`${data.featureImage.url}${resizeAndConvertImage(600)}`}
            alt={data.featureImage.alt}
            width="350"
            className="video-thumb"
          />}
        </div>
        <div className="platform-detail videos">
          <div className="small-copy">{data.publishedOn}</div>
          <div className="tn-spacing" />
          <div className="blog-title-heading">
            <h6 className="h6">{data.blogTitle}</h6>
          </div>
          {data.author && <div className="blog-author-content">
            { data.author.authorImage && <img src={data.author.authorImage.url} alt={data.author.authorImage.alt} className="author-avatar" />}
            <div className="author-name">{data.author.authorName}</div>
          </div>}
          <div className="sm-spacing" />
        </div>
      </Link>
    </React.Fragment>
  );
};
