import React from 'react'
import { graphql } from 'gatsby'
import BlogCategory from "../components/blog/home/blogCategory";
import { getSafe } from '../utils/common';
import { HelmetDatoCms } from "gatsby-source-datocms";
import Notification from "../components/header/notification";
import ReactPaginate from 'react-paginate';
import { Pagination } from '../utils/pagination';
import Layout from "../layouts";

export class BlogByCategoryPage extends React.Component {    

  constructor(props){
    super(props);
    let {
      data: {
        allDatoCmsBlogPost,
      }
    } = props;

    let blogs = getSafe(() => allDatoCmsBlogPost.edges);
    let blogPaging = new Pagination(blogs, 21);

    this.state = {
      blogPaging,
      data: blogPaging.pageChanged()
    };
  }

  handlePageClick = data => {
    let page = data.selected + 1;
    this.setState({
      data: this.state.blogPaging.pageChanged(page)
    })
  };

  render(){
    let {
      data: {
        datoCmsBlogHome,
        allDatoCmsNotification: { edges: notifications }
      }
    } = this.props;

    let blogs = this.state.blogPaging.allItems;
    const categoryName = blogs && blogs[0].node.category.categoryName;
    const categoryDescription = blogs && blogs[0].node.category.description; 

    return (
      <Layout>
        <Notification
          notifications={notifications}
        />
        <HelmetDatoCms seo={datoCmsBlogHome.seoMetaTags} />
        <header className="main-header about">
          <div className="container grid">
            <BlogCategory data={this.state.data} category={categoryName} categoryDescription={categoryDescription} />
            {this.state.blogPaging.isShowPagination &&  
              <ReactPaginate       
                previousLabel={''}
                nextLabel={''}      
                pageClassName={'page-number'}  
                breakClassName={'page-number'}
                breakLabel={'...'} 
                pageCount={this.state.blogPaging.pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={this.handlePageClick}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
              />
            }            
          </div>
        </header>
      </Layout>
    );    
  }  
}

export default BlogByCategoryPage;

export const query = graphql`
query BlogsByCategory($slug: String!) {
    datoCmsBlogHome {
      id
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    allDatoCmsNotification {
      edges {
        node {
          id
          content
          show
          weeks
          notificationPosition
          linkToPage {
            id
            page
          }
          backgroundColor {
            hex
          }
        }
      }
    }
    allDatoCmsBlogPost(
      filter: {category: {slug: {eq: $slug}}}
      sort: { fields: [publishedOn], order: DESC }
    ) {
      edges {
        node {
          id
          slug
          blogTitle
          publishedOn(formatString: "MMM D, YYYY")
          featureImage {
            url
          }
          author {
            authorImage {
              url
            }
            authorName
          }
          category {
            categoryName
            description
          }
        }
      }
    }
  }
`
