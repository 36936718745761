import React from "react";
import BlogPreview from "../blogPreview";
import { Link } from "gatsby";

export default ({ data, category, categoryDescription, seeAllLink }) => {
  const previewBlogTmpl = data.map(block => {
    return <BlogPreview data={block.node} />;
  });
  const categoryDescriptionTmpl =
    categoryDescription !== undefined ? (
      <React.Fragment>
        <div className="tn-spacing" />
        <div style={{textAlign: "left"}} className="p">{categoryDescription}</div>
      </React.Fragment>
    ) : (
      ""
    );
  const seeAllLinkTmpl =
    seeAllLink !== undefined ? (
      <React.Fragment>
        <Link
          to={`/blog/category/${seeAllLink}`}
          className="link-text offset-top w-hidden-tiny"
        >
          See All
        </Link>
      </React.Fragment>
    ) : (
      ""
    );
  return (
    <React.Fragment>
      <div className="flex-container space-between">
        <h2 className="h2 default">{category}</h2>
        {seeAllLinkTmpl}
        {categoryDescriptionTmpl}
      </div>
      <div className="md-spacing" />
      <div className="grid-container-3">
        {previewBlogTmpl}
      </div>
      <div className="md-spacing" />
    </React.Fragment>
  );
};
